import { render, staticRenderFns } from "./AlbumManager.vue?vue&type=template&id=77966576&scoped=true&"
import script from "./AlbumManager.vue?vue&type=script&lang=ts&"
export * from "./AlbumManager.vue?vue&type=script&lang=ts&"
import style0 from "./AlbumManager.vue?vue&type=style&index=0&id=77966576&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77966576",
  null
  
)

export default component.exports