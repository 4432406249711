




































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store/store.vuex'
import fb from 'firebase/app'
import Album = fb.firestore.DocumentData
import Check from '@/components/Check.vue'
import firebase from '../services/firebase'

@Component({ components: { Check } })
export default class AlbumManager extends Vue {
  selectedAlbums: Album[] = []

  mounted() {
    this.$store.dispatch('firestore/getAlbums')
  }

  selectAlbum(album: Album) {
    if (!this.isAlbumSelected(album)) {
      this.selectedAlbums.push(album)
    } else {
      this.unselectAlbum(album)
    }
  }

  unselectAlbum(album: Album) {
    if (this.isAlbumSelected(album)) {
      const index = this.selectedAlbums.indexOf(album)
      if (index > -1) {
        this.selectedAlbums.splice(index, 1)
      }
    }
  }

  openAlbum(albumId: string) {
    this.$router.push(`/photos-manager/albums/${albumId}`)
    this.$store.dispatch('app/setSelectedPhotoManagerComponent', 'albums')
  }

  deleteAlbums() {
    this.selectedAlbums.forEach((album, index) => {
      firebase.albums
        .doc(album.id)
        .delete()
        .then(() => {
          if (index === this.selectedAlbums.length - 1) {
            this.$store.dispatch('firestore/getAlbums')
            this.$swal({
              position: 'bottom',
              text: this.$tc('album.albumDeleted', this.selectedAlbums.length),
              showConfirmButton: false,
              timer: 1500
            })
            this.selectedAlbums = []
          }
        })
    })
  }

  addAlbum() {
    this.$swal({
      title: 'What the title of new album?',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'OK',
      preConfirm: albumTitle => {
        this.$store.dispatch('firestore/createAlbum', albumTitle)
      }
    }).then(result => {
      if (result.isConfirmed) {
        this.$store.dispatch('firestore/getAlbums')
      }
    })
  }

  @Watch('selectedAlbums')
  isAlbumSelected(album: Album): boolean {
    return this.selectedAlbums.includes(album)
  }

  get albums() {
    return vxm.firestore.albums
  }
}
